import ApiClient from '../api'
import Status from '../models/Status'

const api = new ApiClient(Status.modelName)

export const getStatuses = async (params) => {
  const { data: statuses } = await api.getAll(params)

  return statuses
}

export const getStatus = async (id) => {
  const { data: status } = await api.getOne(id)

  return status
}

export const createStatus = async (formData) => {
  const { data: status } = await api.create(formData)

  return status
}

export const updateStatus = async (formData) => {
  const { data: status } = await api.update(formData)

  return status
}

export const removeStatus = async (id) => {
  return await api.delete(id)
}
