<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Статусы</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-countries"
                >
                  Добавить
                </button>
              </div>
              <div class="col-md-4 offset-md-4 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="setStatus"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0">
            <div v-if="statuses" class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="light">
                  <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(status, index) in statuses" :key="index">
                  <td>{{ status.name.ru }}</td>
<!--                  <td>-->
<!--                    {{ status.descriptions.ru }}-->
<!--&lt;!&ndash;                    <textarea class="form-control" v-model="status.descriptions.ru"></textarea>&ndash;&gt;-->
<!--                  </td>-->
                  <td>
                    {{ status.descriptions.ru }}
<!--                    <textarea class="form-control" v-model="status.descriptions.uz"></textarea>-->
                  </td>
                  <td class="text-center">
                    <input
                      v-model="finalStatus"
                      class="form-check-input"
                      type="radio"
                      name="status"
                      :checked="status.status"
                      @change="onChangeStatus(status.id)"
                    >
                  </td>
                  <td>
                    <button class="btn btn-link text-danger" @click.prevent="deleteStatus(status.id)">&times; Удалить</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="statusModal"
      mainClass="fade"
      :tabindex="-1"
      id="add-countries"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Добавление статуса</h1>

        <Form class="row mt-4" @submit="addStatus" v-slot="{ meta }">
          <div class="col-md-8">
            <label for="name" class="form-label">Название</label>
            <Field id="name" type="text" name="names[ru]" class="form-control" aria-label="Large file input example"
                   rules="required" />
            <ErrorMessage name="name[ru]" class="text-danger" />
          </div>

          <div class="col-md-4 d-flex align-items-center">
            <div class="form-check form-switch mb-3">
              <input v-model="statusCheckbox" class="form-check-input" type="checkbox" name="status"
                     id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Финальный статус</label>
            </div>
          </div>

          <div class="mt-4">
            <div>
              <label for="text_ru" class="form-label">Текст</label>
              <Field
                v-slot="{ field }"
                id="text_ru"
                type="text"
                name="descriptions[ru]"
                rules="required"
              >
                <textarea v-bind="field" class="form-control" rows="5"></textarea>
              </Field>
              <ErrorMessage name="description[ru]" class="text-danger" />
            </div>

<!--            <div>-->
<!--              <label for="text-uz" class="form-label mt-4">Сообщение на узбекском</label>-->
<!--              <Field v-slot="{ field }" id="text-uz" type="text" name="description[uz]" rules="required">-->
<!--                <textarea v-bind="field" class="form-control" rows="5"></textarea>-->
<!--              </Field>-->
<!--              <ErrorMessage name="description[uz]" class="text-danger" />-->
<!--            </div>-->

            <div>
              <label for="flight-number" class="form-label mt-4">С Номером рейса</label> <br>
              <Field v-slot="{ field }" id="flight-number" name="with_flight_number" rules="required">
                <input v-model="isChecked" v-bind="field" type="checkbox" class="form-check-input" value="0" />
              </Field>
              <br>
              <ErrorMessage name="with_flight_number" class="text-danger" />
            </div>
          </div>

          <div class="text-center my-5">
            <button :disabled="!(meta.valid && meta.dirty)" type="submit" class="btn btn-primary">Сохранить</button>
          </div>
        </Form>
      </model-body>
    </modal>
  </div>
</template>

<script>
import modal from '../../../components/bootstrap/modal/modal'
import modelHeader from '../../../components/bootstrap/modal/model-header'
import modelBody from '../../../components/bootstrap/modal/model-body'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { getStatuses, createStatus, removeStatus, updateStatus } from '../../../services/statuses.service'

export default {
  name: 'StatusesIndex',
  data: () => ({
    currentPage: 1,
    isChecked: false,
    tableHeaders: [
      'Название статуса',
      'Текст сообщения (русский)',
      // 'Текст сообщения (узбекский)',
      'Финальный',
      'Действия'
    ],
    statuses: [],
    statusCheckbox: false,
    finalStatus: 0,
    updateFormData: null
  }),
  components: {
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    async fetchStatuses () {
      const { data: statuses } = await getStatuses()
      this.statuses = statuses.data
    },
    async addStatus (values) {
      const formData = new FormData()

      const formValue = { ...values, with_flight_number: this.isChecked ? 1 : 0 }

      formData.append('status', this.statusCheckbox === false ? 0 : 1)
      Object.entries(formValue).forEach(([name, value]) => {
        formData.append(name, value)
      })

      await createStatus(formData)
      window.location.reload()
    },
    async deleteStatus (id) {
      await removeStatus(id)
      await this.fetchStatuses()
    },
    async setStatus () {
      await updateStatus(this.updateFormData)
    },
    async onChangeStatus (statusId) {
      const formData = new FormData()

      formData.append('id', statusId)
      formData.append('status', this.finalStatus === 'on' ? '1' : '0')

      this.updateFormData = formData
    }
  },
  async created () {
    await this.fetchStatuses()
  }
}
</script>
